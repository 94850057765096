var swipe = {
	init: function () {

		var swiper = new Swiper('.slide-us', {
			loop: true,
			// autoplay: {
			// 	delay: 4500,
			// },
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			slidesPerView: 1,
			breakpoints: {
				// when window width is >= 480px
				// 480: {
				// 	slidesPerView: 2
				// },
				// when window width is >= 640px
				1024: {
					spaceBetween: 30,
					slidesPerView: 3,
					// slidesPerColumn: 2,
					// slidesPerColumnFill: 'row',
					// centeredSlides: false,
				}
			}	
		});
		var swiper = new Swiper('.slide-press', {
			// loop: true,

			// autoplay: {
			// 	delay: 4500,
			// },
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			slidesPerView: 1,
			breakpoints: {
				// when window width is >= 480px
				768: {
					spaceBetween: 20,
					slidesPerView: 2
				},
				// when window width is >= 640px
				1024: {
					spaceBetween: 30,
					slidesPerView: 3,
					// slidesPerColumn: 2,
					// slidesPerColumnFill: 'row',
					// centeredSlides: false,
				}
			}	
		});			
		var swiperReels = new Swiper('.slide-reels', {
			loop: true,
			// autoplay: {
			// 	delay: 4500,
			// },
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			slidesPerView: 1,
			breakpoints: {
				// when window width is >= 480px
				// 480: {
				// 	slidesPerView: 2
				// },
				// when window width is >= 640px
				1024: {
					spaceBetween: 30,
					slidesPerView: 3,
					// slidesPerColumn: 2,
					// slidesPerColumnFill: 'row',
					// centeredSlides: false,
				}
			}	
		});	
		var swiperDreams = new Swiper('.slide-dreams', {
			loop: true,
			autoplay: {
				delay: 4500,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			slidesPerView: 1,
			spaceBetween: 30	
		});	
	}
};

swipe.init();